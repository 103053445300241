<template>
  <div ref="scrollToMe" class="game">
    <div class="game__header">
      <img class="role__icon" :src="`./role_${getActiveGame}.png`" alt="" />
      <p class="game__header-name">{{ username }}</p>
      <p class="game__header-role">{{ roles[getActiveGame] }}</p>
      <svg-icon class="home__pixel" name="home_pixel"></svg-icon>
      <p class="game__header-level">Рівень безпеки:</p>
      <p class="game__header-number">{{ getSecurityPoints }}</p>
    </div>
    <div class="game__main">
      <div class="game__main-task">
        <div @click="backToRole" class="task__back">
          <svg-icon class="back-arrow" name="back_arrow"></svg-icon>
          <p class="back-text">Назад до персонажів</p>
        </div>
        <p class="task__number">{{ getActiveRound + 1 }} / 5</p>
        <p class="task__text">
          {{
            tasks[`role_${getActiveGame}`][`question_${getActiveRound + 1}`]
              .task
          }}
        </p>
      </div>
      <div class="game__main-image">
        <img class="main__image" :src="imageSrc" alt="" />
      </div>
    </div>
    <div class="game__options">
      <ButtonBase
        @click="selectOption(button)"
        v-for="button in [1, 2, 3, 4]"
        :key="button"
      >
        {{
          tasks[`role_${getActiveGame}`][`question_${getActiveRound + 1}`][
            `option_${button}`
          ].text
        }}
      </ButtonBase>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import tasks from "@/library/tasks.json";
import { getFromLS, isLSHasItem } from "@/library/helpers";

export default {
  name: "Game",
  data() {
    return {
      imageSrc: "",
      tasks,
      username: "",
      roles: ["Громадянин", "Волонтер", "Голова гумштабу"],
    };
  },
  created() {
    isLSHasItem("username")
      ? (this.username = getFromLS("username"))
      : (this.username = "");
    this.imageSrc = `./${this.getActiveGame}_${this.getActiveRound + 1}.jpg`;
  },
  computed: {
    ...mapGetters(["getActiveGame", "getActiveRound", "getSecurityPoints"]),
  },
  watch: {
    getActiveRound() {
      const el = this.$refs.scrollToMe;
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  updated() {
    const el = this.$refs.scrollToMe;
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
    this.imageSrc = `./${this.getActiveGame}_${this.getActiveRound + 1}.jpg`;
    let nextImage = new Image();
    nextImage.src = `./${this.getActiveGame}_${this.getActiveRound + 2}.jpg`;
  },
  mounted() {
    const el = this.$refs.scrollToMe;
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  },
  methods: {
    backToRole() {
      this.$router.push({ name: "role-select" });
    },
    selectOption(number) {
      this.$store.commit("modal/SET_MODAL", {
        state: true,
        name: "answer",
        props: { number },
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.game__header
  display: flex
  padding: 3rem 20rem
  align-items: center
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.05)
  @include xl
    padding: 4rem 4rem 2rem
  @include m
    padding: 2rem 1.6rem 2rem

.role__icon
  max-width: 6.4rem
  max-height: 6.4rem
  @include m
    max-width: 3.2rem
    max-height: 3.2rem

.home__pixel
  max-width: 3.2rem
  max-height: 3.2rem
  margin-left: auto
  @include ms
    display: none

.game__header-name
  font-weight: 700
  margin-left: 2rem
  @include m
    margin-left: 1rem

.game__header-role
  margin-left: 0.8rem
  font-size: 1.4rem
  color: $green-main

.game__header-level
  margin-left: 0.8rem
  @include ms
    margin-left: auto

.game__header-number
  margin-left: 0.8rem
  font-size: 2.4rem
  font-weight: 700

.game__main
  display: flex
  gap: 2.4rem
  padding: 4.6rem 20rem 3.4rem
  max-width: 150rem
  @include xl
    padding: 4rem 4rem 2rem
  @include m
    padding: 2rem 1.6rem 2rem
    flex-wrap: wrap

.game__main-task
  padding: 0 4rem 0 0
  @include m
    padding: 0

.task__back
  display: flex
  gap: 2rem
  align-items: center
  cursor: pointer
  margin-bottom: 5.6rem
  @include m
    margin-bottom: 3.2rem

.back-arrow
  max-width: 1.6rem
  max-height: 1.4rem

.back-text
  color: $green_main
  font-size: 1.6rem

.task__number
  color: #8D8D8D
  font-size: 1.6rem
  margin-bottom: 2.4rem

.game__main-task, .main__image
  flex: 1 0 calc(50% - 1rem)

.game__options
  display: grid
  grid-template: 1fr 1fr / 1fr 1fr
  gap: 2.4rem
  padding: 0rem 20rem 4.8rem
  @include xl
    padding: 4rem 4rem 2rem
  @include m
    padding: 2rem 1.6rem 2rem
    flex-wrap: wrap
  @include ms
    grid-template: 1fr / 4fr
    gap: 1.2rem
</style>
